$assetPath: '../../assets/images/sportsbook/background';

#bg {
  background-image: linear-gradient(var(--theme1-bg), var(--theme2-bg));
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;

  .center-content {
    position: absolute;
    top: 45%;
    left: 50%;
    display: flex;
    flex-direction: column;

    transform: translate(-50%, -50%);
    .logo {
      margin-bottom: 4px;
      width: var(--login-logo-width);
      height: var(--login-logo-height);
    }

    .logo_loading {
      display: block;
      justify-content: center;
      .logo {
        height: 60px;
      }
      .logo-text {
        text-align: center;
        margin-bottom: 4px;
        font-size: 12px;
        text-align: center;
        font-size: 13px;
        opacity: 0.9;
        font-family: -webkit-body;
      }
      .fa-spin {
        width: 100%;
        margin-top: 12px;
        font-size: 24px;
      }
    }
    .logo-text {
      text-align: center;
      margin-bottom: 5px;
      font-size: 13px;
      text-align: center;
      opacity: 0.9;
      font-family: -webkit-body;
    }
    .card {
      background-color: #fff;
      background-size: cover;
      border-radius: 8px;
      box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
      margin-top: 10px;
      .MuiInputBase-root {
        border: 0px !important;
        padding: 5px;
        .MuiInputBase-input {
          color: #000 !important;
          background-color: #fff !important;
          padding: 5px;
        }
      }
      .MuiFormControl-root {
        background-color: #fff !important;
      }
    }
  }
}
@media (max-width: 600px) {
  #bg {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .center-content {
      border-radius: 10px;
      display: flex;
      flex-direction: column;
    }
  }
}
