// Components
@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './input.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './header.scss';
@import './scrollbar.scss';
@import './oddsTable.scss';

// Pages
@import './Home.scss';

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');

// body

@font-face {
  font-family: 'RobotoCondensed';
  src: local('RobotoCondensed'),
    url('../fonts/RobotoCondensed-Regular.ttf') format('truetype');
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 12px;
}

/* Backdrop styles */
.MuiBackdrop-root {
  background: rgba(72, 99, 155, 0.85) !important;
}

.hand-pt {
  cursor: pointer;
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 5px;
}

.b-text {
  font-weight: 600 !important;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
  color: var(--site-color);
}

.profit {
  color: var(--ion-color-success) !important;
}

.loss {
  color: var(--ion-color-danger) !important;
}

.back-bet,
.back-odd {
  background: var(--back-odd-background);
}
.lay-bet,
.lay-odd {
  background: var(--lay-odd-background);
}

// index based color
.back-bet,
.back-odd0 {
  background: var(--back-odd-background);
}
.lay-bet,
.lay-odd0 {
  background: var(--lay-odd-background);
}
.back-bet,
.back-odd1 {
  background: var(--back-odd-background2);
  color: var(--odds-font-color);
}
.lay-bet,
.lay-odd1 {
  background: var(--lay-odd-background2);
  color: var(--odds-font-color);
}
.back-bet,
.back-odd2 {
  background: var(--back-odd-background1);
  color: var(--odds-font-color);
}
.lay-bet,
.lay-odd2 {
  background: var(--lay-odd-background1);
  color: var(--odds-font-color);
}

// Carousel styles
.carousel .slide {
  background: none !important;
}
.primary-a-text {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: bolder !important;
  color: var(--primary-color);
}

.primary-b-text {
  font-family: 'Roboto Condensed', sans-serif !important;
  font-weight: 500;
  color: var(--primary-color);
  font-size: 16px;
}

@keyframes color-change {
  0% {
    color: red;
  }
  50% {
    color: var(--ion-color-success);
  }
  100% {
    color: var(--ion-color-primary);
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }
  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    color: red;
    transform: scale(1);
  }
  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .tab-view {
    display: none !important;
  }

  .odds-web-view {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lap-view {
    display: none !important;
  }

  .odds-mob-view {
    display: none;
  }
}

.font-roboto {
  font-family: Roboto;
}

// span,
// div {
//   color: var(--ion-color-light);
// }

.alert-danger {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: 0.25rem;
  color: #721c24;
  background-color: #f8d7da;
}
.MuiTableCell-sizeSmall {
  padding: 0px;
  .MuiTableCell-head {
    background-color: var(--grey-bg) !important;
  }
}
.ion-select-popover {
  background-color: #fff !important;
}
.ion-list ion-item:not(:last-child) {
  background-color: #fff !important;
}
.list-md {
  background-color: #fff !important;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  color: var(--site-color) !important;
}

.submit-form-btn {
  margin-top: 1.3rem;
  background-color: var(--theme1-bg) !important;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-transform: capitalize;
  width: 100%;
}

// Sport Info icons
.fa-tv {
  color: #000;
  margin: 0 2px;
}

.fa-circle {
  color: #97cc00;
  margin: 0 2px;
}

// No in-play events msg
.no-inplay-msg {
  color: #000;
  display: block;
  margin-top: 30px;
  padding-left: 20px;
}

.all-markets-link {
  cursor: pointer !important;
}
.err-box {
  margin-top: 10px;
  background: rgba(255, 87, 87, 0.2);
  border: 0.2px solid #ff5757;
  border-radius: 12px;
  padding: 4px;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  .closeIcon {
    cursor: pointer;
  }
  .msg {
    width: 80%;
    white-space: break-spaces;
    padding: 4px;
    color: red;
  }
}
.success-box {
  margin-top: 10px;
  background: rgba(84, 201, 117, 0.2);
  border: 0.2px solid #54c975;
  border-radius: 12px;
  padding: 4px;
  color: #54c975;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;

  .msg {
    width: 80%;
    white-space: break-spaces;
    padding: 4px;
    color: #54c975;
  }
  .closeIcon {
    cursor: pointer;
  }
  .select-text {
    font-size: 13px !important;
  }
  textarea:focus,
  input:focus {
    outline: none !important;
  }
  input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .msg {
    width: 90%;
    white-space: break-spaces;
    padding: 4px;
  }
}
// ion-content {
//   // overwrite inline styles
//   --offset-bottom: auto!important;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}